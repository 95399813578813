<template>
  <div
    id="defaultModal"
    tabindex="-1"
    v-if="value"
    class="tw-overflow-y-auto tw-z-99999 tw-flex tw-justify-center tw-items-center tw-bg-black tw-bg-opacity-10 tw-overflow-x-hidden tw-fixed tw-top-0 tw-right-0 tw-left-0 tw-bottom-0 tw-z-50 tw-inset-0 tw-h-modal tw-h-screen"
  >
    <div
      class="modal-content tw-relative tw-shadow-md tw-drop-shadow-md tw-h-auto tw-min-h-[20rem] tw-p-4 tw-w-full tw-max-w-[30rem] tw-bg-white tw-rounded-xl"
    >
      <button
        type="button"
        class="tw-text-gray-400 tw-absolute tw-top-2 tw-right-2 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-ml-auto tw-inline-flex tw-items-center"
        @click="onClose"
      >
        <svg
          class="tw-w-5 tw-h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        class="modal-header tw-text-primary tw-font-bold tw-pb-2 tw-border-b"
      >
        {{ title }}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalDialog",
  props: {
    value: Boolean,
    title: {
      type: String,
      default: "-",
    },
  },
  methods: {
    onClose() {
      console.log("onClose");
      this.$emit("on-close");
      this.$emit("input", false)
    },
  },
};
</script>

<style></style>
